<template>
  <div id="home">
        <navbar homepage="false"></navbar>
            <div class="newsletter-container">
                <div class="newsletter-left-column">
                    <h3>Archive</h3>
                    <ul>
                        <li>
                            2018
                            <ul>
                                <li><a class="archive-date" href="01-jan-2018">January</a></li>
                                <li><a class="archive-date" href="01-feb-2018">February</a></li>
                                <li><a class="archive-date" href="01-mar-2018">March</a></li>
                                <li><a class="archive-date" href="01-apr-2018">April</a></li>
                                <li><a class="archive-date" href="01-may-2018">May</a></li>
                                <li><a class="archive-date" href="01-jun-2018">June</a></li>
                                <li><a class="archive-date active" href="01-jul-2018">July</a></li>
                                <li><a class="archive-date" href="01-aug-2018">August</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="newsletter-right-column">
                    <div class="newsletter-right-column-content">
                        <h2>MUSKLE Monthly Newsletter #007</h2>
                        <p class="newsletter-subtitle">
                            "Sugars”
                        </p>
                        <p class="newsletter-date">
                            (July 2018)
                        </p>
                        <p class="newsletter-paragraph">
                            We might as well label it the “bad guy” … But like anything, moderation is key.
                        </p>
                        <p class="newsletter-paragraph">
                            What is sugar? On a macro level, it’s a carbohydrate. We’ll have a look into some of the common macros in the August edition. Your brain and other major organs require sugar to function properly but too much can have adverse effects.
                        </p>
                        <p class="newsletter-paragraph">
                          Sugar is a complex molecule which can be broken down into simpler forms which the body can then absorb. Some common types of sugar molecules:
                          <ul class="styled-list">
                                <li>
                                    Fructose (found in fruit)
                                </li>
                                <li>
                                    Sucrose (found in table sugar)
                                </li>
                                <li>
                                    Lactose (found in milk)
                                </li>
                            </ul>
                        </p>
                        <p class="newsletter-paragraph">
                            Sugar is absorbed in the intestines, and from there travels through the liver for a quick pitstop to be absorbed and used as energy or passed on to the rest of the body to be stored as fat, to be used at a later date.
                        </p>
                        <p class="newsletter-paragraph">
                            Australian’s are consuming on average, around 120g of sugar per day. That’s over 200% of the recommended daily intake. An increased sugar intake will dramatically increase the chances of developing cardio-vascular disease, diabetes, and a myriad of other health issues.
                        </p>
                        <p class="newsletter-paragraph">
                            “That Sugar Film” released in 2015 dives into the health effects of a high-sugar diet and I recommend it to anyone who wants to limit sugar in their diet.
                        </p>
                        <p class="newsletter-paragraph">
                            Many people who come to me for personal training advice will forget the main rule.
                        </p>
                        <p class="newsletter-paragraph">
                            <span class="bold">70% food:30% training. You’ll never out-train a bad diet.</span>
                        </p>
                        <p class="newsletter-preview">
                            Next month sneak peak - macros
                        </p>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#home {
  margin: 0;
  padding: 0;
}
.styled-list {
                list-style-type: circle;
                padding-top: 0;
                margin-top: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
            }
  h2 {
      font-family: 'Roboto';
      margin-bottom: 25px;
      text-align: center;
  }
  p {
      font-family: 'Roboto', sans-serif;
  }
  ul {
      list-style-type: none;
  }
  li {
      padding: 5px 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
  }
  .archive-date {
      color: #000;
      text-decoration: none;
  }
  .archive-date:hover {
      text-decoration: underline;
  }
  .active {
      font-weight: 900;
  }
  .newsletter-container {
      margin-top: 125px;
      display: flex;
  }
  .newsletter-left-column {
      flex-grow: 1;
      padding: 10px;
      margin-top: 20px;
      margin-left: 20px;
      max-width: 160px;
  }
  .newsletter-right-column {
      display: flex;
      flex-grow: 5;
      justify-content: center;
      margin-right: 80px;
  }
  .newsletter-right-column-content {
      margin: 50px 100px;
      border: 3px double #ddd;
      padding: 0px 30px;
      max-width: 595px;
  }
  .newsletter-subtitle {
      font-size: 20px;
      font-weight: 300;
  }
  .newsletter-date {
      font-size: 14px;
      font-weight: 500;
  }
  .newsletter-paragraph {
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
  }
  .newsletter-preview {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      padding-top: 40px;
  }
  .italic {
      font-style: italic;
  }
  .bold {
      font-weight: 900;
  }

  @media only screen and (max-width: 750px) {
      h2 {
          font-size: 18px;
      }
      .newsletter-container {
          flex-direction: column;
      }
      .newsletter-right-column {
          margin-right: 0;
      }
      .newsletter-right-column-content {
          margin: 10px 10px;
          padding: 0 10px;
      }
      .newsletter-subtitle {
          font-size: 16px;
      }
      .newsletter-date {
          font-size: 13px;
      }
      .newsletter-paragraph {
          font-size: 14px;
      }
      .newsletter-preview {
          font-size: 13px;
      }
  }
</style>
